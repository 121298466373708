<template>
  <div class="hv100_main back__img_new main-image relative">
    <div class="container-full">
      <header-main></header-main>
    </div>
    <section class="main-hero">
        <div class="main-hero__info">
            <p>Проект реализуется при поддержке Министерства спорта и Федерального проекта «Спорт — норма жизни» национального проекта «Демография».</p> 
        </div>
    </section>

    <div v-if="showmodallogout" class="modal-footer--open">
      <div class="modal-wrapper">

        <a @click="showmodallogout = false" class="close--modal--footer"></a>

        <div class="modal-container--footer">
          <div class="modal-body--foter">
            <div class="row margin-bottom--footer-modal">
              <h4 class="logout-modal--h4">Вы действительно хотите выйти?</h4>
            </div>
            <div class="d-flex justify-content-between">
                <button @click="showmodallogout = false" class="detail--button disablebutton" style="margin-right: 20px;">Отмена</button>
                <button class="detail--button" @click="logout()">
                  Выйти
                </button>           
            </div>

          </div>
        </div>
      </div>
    </div>

  <div @click="menumobile = false" v-if="menumobile" class="modal-shows--mobile"></div>
  
  </div>

  <!-- :style="{'background-image': 'url(' + require('@/assets/img/section-second.png') + ')'}" -->

  <section class="black__block_konkurs d-hiden" >
    <div class="d-flex justify-content-center w-100 section-second">
      <div class="d-hiden section-second__block">
        <button class="button-massive__gradient red" @click="goTo('/konkurs')">Принять участие в проекте</button>
        <div>
          <h3>Мероприятие появится уже скоро</h3>
          <p>
            *Мероприятие будет проводиться с 09.09.2024 по 25.12.2025 
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="section-sinfo">
      <div class="section-sinfo__title">
          <h1>НЕМНОГО <br><span><i>О</i> ФЕСТИВАЛЕ</span></h1>
      </div>
      <div class="section-sinfo__about container">
          <div class="section-sinfo__img">
            <img src="@/assets/img/about-fesitval.png">
          </div>
          <div class="section-sinfo__other">
              <div class="section-sinfo__redirect">
                <p><strong>Проект «Россия в движении»</strong> — это масштабный фестиваль массового спорта, объединяющий 30 регионов России. Его цель – пропаганда здорового образа жизни и повышение спортивной активности среди всех возрастных групп. Фестиваль не только укрепляет единство нации, но и оказывает важную поддержку ветеранам и участникам спецопераций, помогая им адаптироваться к мирной жизни.</p>
                <p><strong>Особое внимание проект уделяет сохранению традиционных ценностей, сотрудничая с Русской Православной Церковью и Духовным управлением мусульман России, что способствует укреплению межконфессионального и межнационального диалога.</strong></p>
                <router-link to="/about_project" class="button-redlink">Подробнее о конкурсе</router-link>
              </div>            
              <div class="other-img">
                <img src="@/assets/img/section-ong1.png">
              </div>
          </div>
      </div>
  </section>



  <section class="section-faq position-relative">
    <div class="container">
      <div class="section-faq__title">  
        <h1><span>У вас есть <strong>вопросы?</strong></span><br>Мы уже <span class="red">ответили</span> <strong>на них</strong></h1>
      </div>
      <div class="section-faq__flex">
        <div class="section-faq__img">
            <img src="@/assets/img/faqimg.png">
        </div>
        <div class="faq">
          <div class="block__qu" v-for="(list, index) in asqst">
            <div class="d-flex justify-content-between pointer" @click="showBlock(showJobBlock == index + 1 ? 0 : index + 1)">
              <h3 class="qu_text">
                {{ list.question }} 
              </h3>
              <div>
                <button class="btn_icon" @click="showBlock(showJobBlock == index + 1 ? 0 : index + 1)">
                  <span class="open_job" :class="{'open_job_active': showJobBlock == index + 1}"></span>
                </button>                
              </div>
<!--               <div v-else @click="showBlock(showJobBlock == index + 1 ? 0 : index + 1)">
                <button class="btn_icon">
                  <span class="open_job"></span>
                </button>                
              </div> -->
            </div>
            <div class="open_block" v-if="showJobBlock == index + 1">
              <p class="open_qu_text">Ответ: <span v-html="list.answer"></span></p>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </section>

  <posli-list type="posli" :items="posli"></posli-list>

  <div ref="parthners" class="black__block_konkurs" :style="{'background-image': 'url(' + require('@/assets/img/partners.svg') + ')'}">
    <div class="w-100 padding_center_block container paddingcontainernew ">
      <h4 class="parthners__text">
        Партнеры проекта
      </h4>
      <div class="block__part__flex">
<!--         <div class="image-parthners">
          <img src="@/assets/img/pathner1.png">
        </div>
        <div class="image-parthners">
          <img src="@/assets/img/pathner2.png">
        </div> -->
        <div class="image-parthners">
          <img src="@/assets/img/pathner3.png">
        </div>
      </div>
    </div>
  </div>

  <div class="map block__margin_qu container paddingcontainernew">
    <div class="text-center regions-inner">
       <h4><i>УЧАСТНИКИ</i> <br><span>ПРОЕКТА</span></h4>
       <ul class="regions-inner__row">
         <li v-for="list, index in regions">
           {{ index + 1 }}. {{ list.title }}
         </li>
       </ul>
       <div class="btns">
         <a target="_blank" href="https://xn--2024-f4dliahtc3d.xn--p1ai/polojenie-o-konkurse.pdf" class="button-redlink">Скачать положение</a>
       </div>
    </div>
  </div>

  <div ref="contact" class="contact-section container paddingcontainernew" :style="{'background-image': 'url(' + require('@/assets/img/contact-moscow.svg') + ')'}">
    <h4 class="contact___konkurs">
      К<i>О</i>НТАКТЫ
    </h4>
    <div class="row mb60">
        <div class="row col-lg-8 pt-5 mt-3">
<!--           <div class="col-lg-6 mb-5">
            <h5 class="contact__h5">Номер для связи:</h5>
            <a href="tel:+7 911 001 255-11" class="contact__p">+7 911 001 255-11</a>
          </div> -->
          <div class="col-lg-6 mb-5">
            <h5 class="contact__h5">Почта для связи:</h5>
            <a href="mailto: russia@sport.ru" class="contact__p">russia@sport.ru</a>        
          </div>

          <div class="col-lg-12">
            <div class="mb-5">
              <h5 class="contact__h5">Доверенные лица:</h5>
              <p class="contact__p">Руководитель проекта: Ансимов Юрий Николаевич (Президент Спортобщества «РОССИЯ»)</p>
            </div>
          </div>

          <div class="col-lg-6 mb-5">
            <h5 class="contact__h5">Номер для связи:</h5>
            <a href="tel:+7 936 100 2050" class="contact__p">+7 936 100 2050</a>
          </div>
          <div class="col-lg-6 mb-5">
            <h5 class="contact__h5">Почта для связи:</h5>
            <a href="mailto: media@fsop-russia.ru" class="contact__p">media@fsop-russia.ru</a>        
          </div>

          <div class="col-lg-12">
            <div class="mb-5">
              <h5 class="contact__h5">Доверенные лица:</h5>
              <p class="contact__p">Свирилина Яна Валерьевна</p>
            </div>
          </div>

        </div>

        <div class="col-lg-4 contact-flex">
          <div class="contact-flex__image">
              <img src="@/assets/img/anisimov.png">  
          </div>

          <div class="contact-flex__image">
              <img src="@/assets/img/sav.png">  
          </div>
        </div>
    </div>

  </div>

    <div class="w-100 d-flex justify-content-center">
      <button class="btn__join" @click="goTo('/konkurs')">Участвовать в конкурсе</button>
    </div>

  <div class="block___parthners__second paddingcontainernew vg-image">
    <div class="block__part__flex">
        <img src="@/assets/img/pathner_4.svg">
        <div class="img-block__parth">
          <img src="@/assets/img/parthner_5.svg">
        </div>
        <img class="img-block__parth"  src="@/assets/img/sportLogo.png">      
    </div>
  </div>

  <div class="black__block_konkurs coloreNer">
    <div class="d-flex justify-content-between w-100 pt__footer container paddingcontainernew">
        <div class="w-100 padding__footer">
          <div class="d-flex justify-content-between width__footer">
          <router-link class="rd-nav-link-konkurs-footer" to="/">Главная</router-link>

          <a class="rd-nav-link-konkurs-footer" @click="scrollToContact()">Контакты</a>

          <router-link class="rd-nav-link-konkurs-footer" to="/about_project">О проекте</router-link>

          <a class="rd-nav-link-konkurs-footer" @click="scrollToParthners()">Партнеры</a>   

          <router-link v-if="!$store.state.isAuthenticated" class="rd-nav-link-konkurs-footer" to="/log-in">Войти</router-link>             
          </div>
          <div class="width__footer__sub">
            <a class="rd-nav-link-konkurs-footer">Все права защищены движение2024.рф</a>
          </div>   
        </div>    
    </div>
  </div>    
</template>

<style type="text/css">
.help__konkurs {
  background: #D63727;
  width: 470px;
  opacity: 1;
  padding: 1.25rem 1.5rem;
  border: none;
  border-radius: 33px;  
}

.logo__parth {
  font-style: normal;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 15px;
  text-align: center;

  color: #231F20;  
}

.mt_top_video {
  margin: 4rem 0rem;
}

.img-block__parth {
  width: auto;
  padding: 6rem;
  height: 390px;
  transform: translateY(-20px);
}

.help__konkurs h4 {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
  /* or 242% */

  margin-bottom: 10px;
  color: #FFFFFF;
}

.help__konkurs p {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  /* or 242% */


  color: #FFFFFF;
}

.map__list li {
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 22px;
line-height: 64px;
/* or 291% */

color: #000000;
text-align: left !important;

}

.block__kvid {
  width: auto;
  padding: 1.5rem 3.5rem;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 100px;
}

.relative {
  position: relative;
}

.map___h4 {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 64px;
  /* or 194% */
  text-align: center;

  color: #282A5F;
}

.pos___parth {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.pos___parth h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px !important; 
  line-height: 24px;

  color: #000000;  
}

.h-tag {
  margin-top: 60px;
  margin-bottom: 60px;
}

@media (max-width: 1024px) {
  .h-tag {
      margin-top: 30px !important;
      margin-bottom: 50px !important;
  }  
}

.active__qu {
  color: #D63727 !important;
}

.qu_text{
  font-weight: 600;
}

.h-tag h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 24px;

  color: #000000;    
}

.pos___parth p {
  display: block;
  padding-left: 20px;
  margin-top: 10px;
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  color: #000000;  
}

.btn__join {
  transition: 0.3s;
}

.btn__join:hover {
  transform: scale(1.025);
}

.block__part__flex a {
  text-align: center;
}
</style>

<script>
import axios from 'axios'
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import Posli from '@/components/Posli/index'

export default {
  name: 'HomeView',
  data() {
    return {
         quest: [
          {
            title: "Как участвовать в проекте России в движении?",
            disk: 'Зарегистироваться на сайте, пройти в Личный кабинет, пройти в раздел "видео тренировки", пройти в подраздел "участие в конкурсе", выбрать возрастную категорию, повторить конкурсную связку видео-тренировки по аэробике и прислать нам.',
          },
          {
            title: "Могу ли я участвовать с друзьями?",
            disk: "В конкурсной части проекта могут участвовать граждане от 16 до 65 лет. <br> Участие в проекте в целом может принять любой желающий",
          },
          {
            title: "Я прошел регистрацию, но не понял, как участвовать в конкурсе, что делать?",
            disk: "Обратитесь к координатору проекта он свяжется и проконсультирует по всем вопросам участия в проекте.",
          },
          {
            title: "Куда я могу отправить видео для участия в конкурсе?",
            disk: "Видео для участия в конкурсе вы отправляете на почту гранта либо в форму обратной связи в личном кабинете.<br>Требования к видео: видео пересылается в формате ссылки на облачное хранилище , в письме к видео необходимо указать ФИО и подтверждённую почту участника гранта  и контакт для связи .",
          },
        ],     
        regions: [
          {
            "title": "Брянская область",
          },
          {
            "title": "Владимирская область",
          },
          {
            "title": "Волгоградская область",
          },
          {
            "title": "Рязанская область",
          },
          {
            "title": "Кемеровская область",
          },
          {
            "title": "Воронежская область",
          },
          {
            "title": "Мурманская область",
          },
          {
            "title": "Новосибирская область",
          },
          {
            "title": "Тверская область",
          },
          {
            "title": "Томская область",
          },
          {
            "title": "Тульская область",
          },
          {
            "title": "Ульяновская область",
          },
          {
            "title": "Ярославская область",
          },
          {
            "title": "Камчатский край",
          },
          {
            "title": "Краснодарский край",
          },
          {
            "title": "Приморский край",
          },
          {
            "title": "Ставропольский край",
          },
          {
            "title": "Республика Дагестан",
          },
          {
            "title": "Луганская Народная Республика",
          },
          {
            "title": "Республика Татарстан",
          },
          {
            "title": "г. Москва",
          },
          {
            "title": "г. Санкт-Петербург",
          },
          {
            "title": "г. Иркутск",
          },
          {
            "title": "Курганская область",
          },
          {
            "title": "Чувашская Республика",
          },
          {
            "title": "Липецкая область",
          },
          {
            "title": "Нижегородская область",
          },
          {
            "title": "Республика Мордовия",
          },
          {
            "title": "Республика Карелия",
          },
          {
            "title": "Самарская область",
          },
        ],

        showJobBlock: 0,
        menumobile: false,
        showmenu: false,
        showmenushop: false,
        showmodallogout: false,
        status: false,
        posli: [],
        asqst: [],
    }
  },
  watch: {
   '$route.query.redirect'(value) {
      setTimeout(() => {
         console.log(value)
         this.$refs[value].scrollIntoView({ behavior: 'smooth', block: 'start'});
      }, 100)
    }
  },
  mounted() {
   if(this.$route.query.redirect) {
      setTimeout(() => {
         this.$refs[this.$route.query.redirect].scrollIntoView({ behavior: 'smooth', block: 'start'});
      }, 100)
   }
   this.GetPosli()
   this.GetAnswerQuest()
  },
  methods: {
      showBlock(index) {
        this.showJobBlock = index
      },
      goTo(redirect) {
        this.$router.push(redirect)
      },
        openMobileMenu() {
           if (this.menumobile == false) {
              this.menumobile = true;
           } else {
              this.menumobile = false;
           }
        },
        ShowMenu() {
           if (this.showmenu == false) {
              this.showmenu = true;
           } else {
              this.showmenu = false;
           }        
        },
        ShowMenuShop() {
           if (this.showmenushop == false) {
              this.showmenushop = true;
           } else {
              this.showmenushop = false;
           }             
        },
        scrollToContact() {
           this.$refs.contact.scrollIntoView({ behavior: "smooth" });
        },
        scrollToParthners() {
           this.$refs.parthners.scrollIntoView({ behavior: "smooth" });
        },
        ModalOpenLogout() {
          this.showmodallogout = !this.showmodallogout
        },
        async GetPosli() {
            await axios
                .get(`/api/v1/get/posli-all/list`)
                .then(response => {
                    this.posli = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        }, 
        async GetAnswerQuest() {
            await axios
                .get(`/api/v1/get/answer-quest/list`)
                .then(response => {
                    this.asqst = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        }, 
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            localStorage.removeItem("sub_id")
            localStorage.removeItem("id_pay")
            localStorage.removeItem("user")
            localStorage.removeItem("status_pay")
            localStorage.removeItem("anketaCheck")

            this.$store.commit('removeUser')
            this.$store.commit('removeUserInfo')
            this.$store.commit('removeToken')
            this.$store.commit('removeSub')
            this.$store.commit('removePayID')
            this.$store.commit('removePayStatus')

            this.$router.push('/sign-up')
        },
  },
  components: {
    'header-main': Header,
    'posli-list': Posli,
    Footer
  }
}
</script>
