import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Auth

import SignUp from '../views/Auth/SignUp.vue'
import LogIn from '../views/Auth/LogIn.vue'

// Home

import HomeView from '../views/HomeView.vue'
import PageNotFound from '../views/PageNotFound.vue'
import AboutView from '../views/About/AboutView.vue'

// Profile

import MyProfile from '../views/MyProfile/MyProfile.vue'
// import HelpMeProfile from '../views/MyProfile/HelpMeProfile.vue'
import PromoVideos from '../views/MyProfile/PromoVideos.vue'

import KonkursPage from '../views/Konkurs/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
        loginHave: true,
    }
  },
  {
    path: '/log-in',
    name: 'LogIn',
    component: LogIn,
    meta: {
        loginHave: true,
    }
  },
  {
    path: '/about_project',
    name: 'AboutView',
    component: AboutView
  },
  {
    path: '/konkurs',
    name: 'KonkursPage',
    component: KonkursPage,
    meta: {
        requireLogin: true,
    }
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: MyProfile,
    meta: {
        requireLogin: true,
    }
  },
  // {
  //   path: '/help-me',
  //   name: 'HelpMeProfile',
  //   component: HelpMeProfile,
  //   meta: {
  //       requireLogin: true,
  //   }
  // },
  {
    path: '/promo_video',
    name: 'PromoVideos',
    component: PromoVideos,
     meta: {
        requireLogin: true,
    }
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'PageNotFound',
    component: PageNotFound 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } });
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.loginHave) && store.state.isAuthenticated) {
    next({ name: 'Anketa' });
  } else {
    next()
  }
})

export default router
