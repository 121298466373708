<template>
    <div class="full--auth--back" :style="{'background-image': 'url(' + require('@/assets/img/auth.png') + ')'}">
    <HeaderSmall />
        <section class="page-not">
            <div class="page-not__found">
                <div class="not-title">
                    <h1>4 <span>4</span>04</h1>
                    <p>что-то пошло не так ...</p>
                </div>
                <router-link class="button-found" to="/">
                    На главную
                </router-link>
            </div>
        </section>

    </div>
</template>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import HeaderSmall from '@/components/Header/HeaderSmall'

    export default {
        name: 'PageNotFound',
        data() {
            return {

            }
        },
        components: {
            HeaderSmall
        },
        beforeCreate() {
            this.$store.commit('initializeStore')
        },
        mounted() {
            document.title = '404'
            this.errorAlert()
        },
        methods: {
            errorAlert() {
                const message_error = 'Страница не найдена'
                toast({
                    message: message_error,
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                })
            },
        },
        computed: {
      
        },
        created() {

        },
    }
</script>