<template>

    <div class="d-flex justify-content-between w-100 padding-mobile_flex d-unshow__mobile_show" :class="{'absolute-header__mobile': banner}">

      <div>
        <router-link class="rd-nav-link-konkurs mt_mt_main" :class="{'wh_link': user__profile}" to="/">Главная</router-link>
      </div>

      <div class="d-flex mr-5" style="line-height: 36px;">

        <label class="menu-btn" for="menu-toggle" @click="openMobileMenu()">
          <span></span>
        </label>

      </div>       


        <ul class="menubox" v-if="menumobile">
          <a @click="menumobile = false" class="close--modal close--moblemenubox"></a>
          <li class="li-menu-itemss">          
                <router-link class="menu-item" :class="{'wh_link': user__profile}" to="/">Главная</router-link>
          </li>
          <li class="li-menu-itemss">
            <router-link class="menu-item" :class="{'wh_link': user__profile}" to="/?redirect=contact">Контакты</router-link>
          </li>
          <li class="li-menu-itemss">
           <router-link class="menu-item" :class="{'wh_link': user__profile}" to="/about_project">О проекте</router-link>
          </li>
<!--            <li class="li-menu-itemss">
           <router-link class="menu-item" :class="{'wh_link': user__profile}" to="/konkurs">Конкурс</router-link>
          </li> -->
          <li class="li-menu-itemss">
           <router-link class="menu-item" :class="{'wh_link': user__profile}" to="/?redirect=parthners">Партнеры</router-link>
          </li>
          <li class="li-menu-itemss">
            <router-link class="menu-item" :class="{'wh_link': user__profile}" to="/my-profile">Мой аккаунт</router-link>
          </li>
          <li class="position-bottom--mobile li-menu-itemss" v-if="$store.state.anketaCheck" style="flex-direction: column;">
              <router-link class="menu-item colorcccmobile" to="/log-in" style="margin-bottom: 10px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512" style="transform: translate(-1px, -1px); margin-right: 6px;"><path d="M344.7 238.5l-144.1-136C193.7 95.97 183.4 94.17 174.6 97.95C165.8 101.8 160.1 110.4 160.1 120V192H32.02C14.33 192 0 206.3 0 224v64c0 17.68 14.33 32 32.02 32h128.1v72c0 9.578 5.707 18.25 14.51 22.05c8.803 3.781 19.03 1.984 26-4.594l144.1-136C354.3 264.4 354.3 247.6 344.7 238.5zM416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z"/></svg>   
                Заполнить анкету
              </router-link>              

              <a class="menu-item colorcccmobile" @click="ModalOpenLogout()">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512" style="transform: translate(-1px, -1px); margin-right: 6px;"><path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z"/></svg>            
              
                  Выйти
              </a>
          </li>
          <li class="position-bottom--mobile li-menu-itemss" v-else-if="$store.state.isAuthenticated">
            <a class="menu-item colorcccmobile" @click="ModalOpenLogout()">Выйти</a>
          </li>
          <li class="position-bottom--mobile li-menu-itemss" v-else>
            <router-link class="menu-item colorcccmobile" to="/log-in">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512" style="transform: translate(-1px, -1px); margin-right: 6px;"><path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z"/></svg> 
            Войти</router-link>
          </li>
        </ul>

    </div>

	<header class="header-konkurs" :class="{'black_back': user__profile, 'absolute-header': banner}">
      <div class="paddingcontainernew bg-color d-hiden">
         <div class="header-row">
           <div class="d-flex justify-content-between w-100 header-center"> 
            <div class="header-logos">
               <img src="@/assets/img/logos1.png">
            </div>

           	<div class="d-flex justify-content-between width_start">
                <router-link class="rd-nav-link-konkurs" :class="{'wh_link': user__profile}" to="/">Главная</router-link>

                <router-link class="rd-nav-link-konkurs" :class="{'wh_link': user__profile}" to="/?redirect=contact">Контакты</router-link>

                <!-- <router-link class="rd-nav-link-konkurs" :class="{'wh_link': user__profile}" to="/konkurs">Конкурс</router-link> -->

                <router-link class="rd-nav-link-konkurs" :class="{'wh_link': user__profile}" to="/about_project">О проекте</router-link>

                <router-link class="rd-nav-link-konkurs" :class="{'wh_link': user__profile}" to="/?redirect=parthners">Партнеры</router-link>
             </div>
             <div class="d-flex justify-content-end" v-if="$store.state.anketaCheck">
               <a class="rd-nav-link-konkurs mr-right" @click="ModalOpenLogout()">Выйти</a>
             	 <router-link class="rd-nav-link-konkurs" to="/log-in">Заполнить анкету</router-link>         	
             </div>
             <div class="d-flex gap justify-content-end" v-else-if="$store.state.isAuthenticated">
               <a class="rd-nav-link-konkurs mr-right" @click="ModalOpenLogout()">Выйти</a>
               <router-link class="rd-nav-reg-konkurs" to="/my-profile">Мой аккаунт</router-link>
             </div>
             <div class="d-flex gap justify-content-end" v-else>
             	<router-link class="rd-nav-link-konkurs margin_logIn" to="/log-in">Войти</router-link>
             	<router-link class="rd-nav-reg-konkurs" to="/sign-up">Регистрация</router-link>
             </div>

           </div>          
         </div>
      </div>
	</header>

<div v-if="showmodallogout" class="modal-footer--open">
  <div class="modal-wrapper">

    <a @click="showmodallogout = false" class="close--modal--footer"></a>

    <div class="modal-container--footer">
      <div class="modal-body--foter">
        <div class="row margin-bottom--footer-modal">
          <h4 class="logout-modal--h4">Вы действительно хотите выйти?</h4>
        </div>
        <div class="d-flex justify-content-between">
            <button @click="showmodallogout = false" class="detail--button disablebutton" style="margin-right: 20px;">Отмена</button>
            <button class="detail--button" @click="logout()">
              Выйти
            </button>           
        </div>

      </div>
    </div>
  </div>
</div>

  <div @click="menumobile = false" v-if="menumobile" class="modal-shows--mobile"></div>
</template>

<style scoped>
.wh_link {
	color: #fff !important;
}
.black_back {
	background: #1E1E1E;
}
.mr-right {
  margin-right: 6px;
}
.rd-nav-profile-konkurs {
    padding: 0.5rem 1.75rem;
    background: #D63727;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    letter-spacing: -0.01em;
    text-align: center;
    transition: 0.4s;
}

.rd-nav-profile-konkurs:hover {
    color: #fff !important;
    background: rgba(97, 97, 97, 0.26) !important;
}
</style>

<script>
import axios from 'axios'
	export default {
		name: 'Header',
    data() {
      return {
        menumobile: false,
        showmenu: false,
        showmenushop: false,
        showmodallogout: false,
        status: false,
      }
    },
    props: {
      banner: {
        type: Boolean,
        default: false 
      },
      user__profile: {
        type: Boolean,
        default: false      
      },
    },
    methods: {
        openMobileMenu() {
           if (this.menumobile == false) {
              this.menumobile = true;
           } else {
              this.menumobile = false;
           }
        },
        ShowMenu() {
           if (this.showmenu == false) {
              this.showmenu = true;
           } else {
              this.showmenu = false;
           }        
        },
        ShowMenuShop() {
           if (this.showmenushop == false) {
              this.showmenushop = true;
           } else {
              this.showmenushop = false;
           }             
        },
        ModalOpenLogout() {
          this.showmodallogout = !this.showmodallogout
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            localStorage.removeItem("sub_id")
            localStorage.removeItem("id_pay")
            localStorage.removeItem("user")
            localStorage.removeItem("status_pay")
            localStorage.removeItem("anketaCheck")

            this.$store.commit('removeUser')
            this.$store.commit('removeUserInfo')
            this.$store.commit('removeToken')
            this.$store.commit('removeSub')
            this.$store.commit('removePayID')
            this.$store.commit('removePayStatus')

            this.$router.push('/sign-up')
        },
    },
	}
</script>