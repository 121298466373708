<template>
  <div class="back__img_new" :style="{'background-image': 'url(' + require('@/assets/img/auth.png') + ')'}">
    <div class="container-full">
    <Header/>
    <div class="container paddingcontainernew about-view">
      <div class="about-view__video">
          <video class="video" frameborder="0" ref="video" controls>
              <source src="https://s3.timeweb.com/cp80071-9a7cc2fc-e0ef-4b02-9856-d77fd9963f68/videos/trinirovki-so-zvezdami-drozd-video-nomer-3.m4v" type="video/mp4">
          </video>        
      </div>
      <div class="disk__aboutt__vieew">
        <h4 class="template__about_h4">
          Описание
        </h4>
        <p class="template__about_p">
          <strong class="wh8te">Проект "Россия в движении" - это масштабный фестиваль массового спорта, проходящий одновременно 
          в 30 регионах России.</strong> Его цель - пропаганда здорового образа жизни и повышение спортивной активности среди всех возрастных групп. Фестиваль предлагает доступные онлайн-тренировки по разным видам спорта (фитнес, футбол, баскетбол и др.), а также оказывает поддержку ветеранам и участникам спецопераций, помогая им адаптироваться к мирной жизни.  
        </p>
      </div>

      <div class="disk__aboutt__vieew">
        <h4 class="template__about_h4">
          В рамках проекта: 
        </h4>
        <p class="template__about_p">
          * проводятся физкультурные мероприятия, обучающие семинары, онлайн-лекции; <br>
          * создаются методические пособия и учебные программы;<br>
          * проводится Всероссийский конкурс детских рисунков «Россия и спорт».
        </p>
      </div>

      <div class="about-button">
          <h1>Присоединяйтесь к нам и станьте<br>частью большого спортивного движения!</h1>
          <button class="button-massive__gradient white" @click="goTo('/konkurs')">Принять участие в конкурсе</button>
      </div>
      

<!--       <div class="text-center disk__aboutt__vieew_doc">
        <a class="documnet__link__template">Документ</a>
      </div>
    -->
<!--       <div class="w-100 d-flex justify-content-center disk__aboutt__vieew">
        <button class="btn__join__new" @click="goTo('/my-profile')">Принять участие в конкурсе</button>
      </div>

  <div class="block___parthners__second container paddingcontainernew ">
    <div class="block__part__flex">
        <img src="@/assets/img/pathner_4.svg">
        <div class="img-block__parth">
          <img src="@/assets/img/parthner_5.svg">
          <p class="logo__parth">МИНИСТЕРСТВО СПОРТА <br>РОССИЙСКОЙ ФЕДЕРАЦИИ</p>          
        </div>
        <img class="img-block__parth"  src="@/assets/img/parthner_6.svg">      
    </div>
  </div> -->

    </div>
  </div>
  <Footer/>
  </div>

</template>

<style>
.disk__aboutt__vieew {
  margin-bottom: 60px;
}

.btn__join__new {
  background: #282A5F;
  border-radius: 23px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  padding: 2rem;
  line-height: 40px;
  text-transform: uppercase;
  text-align: center;
  /* border-radius: 4px; */
  color: #FAFAEF;
  width: 500px;
  margin-bottom: 50px;
}

.documnet__link__template {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 160% */
  padding-bottom: 2px;
  border-radius: 2px;
  border-bottom: 2px solid #fff;
  letter-spacing: -0.01em;

  color: #fff;  
}

.disk__aboutt__vieew_doc {
  margin-bottom: 100px;
}

.wh8te {
  color: #fff !important;
  font-weight: bold;
}

.template__about_p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  /* or 178% */


  color: #fff;
}

.template__about_h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  /* identical to box height, or 107% */

  color: #fff;  
  margin-bottom: 20px;
}

.block__about_view {
  width: 100%;
  height: 394px;
  background: #2C2C2C;
  border-radius: 12px;  
  margin-bottom: 60px;
}
</style>

<script>
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

export default {
  name: 'HomeView',
  data() {
    return {
         quest: [
          {title: "Как участвовать в конкурсе?"},
          {title: "В каких регионах будет проходить конкурс?"},
          {title: "Что будет после участия в конкурсе?"},
          {title: "Какой то еще один вопрос?"},
          {title: "Могу ли я участвовать с друзьями?"},
          {title: "Я прошел регистрацию, но не понял как участвовать в конкурсе, что делать?"},
          {title: "Что делать, если у меня не получается зарегистрироваться?"},
        ],     

        showJobBlock: 0,
    }
  },
  methods: {
      showBlock(index) {
        this.showJobBlock = index
      },
      goTo(redirect) {
        this.$router.push(redirect)
      },
  },
  components: {
    Header,
    Footer
  }
}
</script>
