<template>
    <div class="full--auth--back" :style="{'background-image': 'url(' + require('@/assets/img/auth.png') + ')'}">

    <HeaderSmall />

    <div class="position--auth-form">
        <div class="max--form">
            <h5 class="auth--title">Вход в аккаунт</h5>
            <form @submit.prevent="submit">
                <div class="field--auth">
                    <input type="text" class="input--auth" v-model="username"
                    >
                    <label class="auth--label"
                    :class="{'valid--auth': validate_username}"
                    >E-mail</label>
                </div>

                <div class="field--auth">
                    <input :type="type" class="input--auth" v-model="password">
                    <label 
                        class="auth--label"
                        :class="{'valid--auth': validate_password}"
                    >Пароль</label>
                    <a @click="openPass()" class="btn-eye--pos">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.556" viewBox="0 0 20 15.556">
                          <path id="eye-solid" d="M9.709,36.459A2.731,2.731,0,0,1,10,36.445a3.333,3.333,0,1,1-3.333,3.333,2.733,2.733,0,0,1,.014-.292,2.549,2.549,0,0,0,1.1.292A2.224,2.224,0,0,0,10,37.556,2.549,2.549,0,0,0,9.709,36.459Zm6.979-1.66a13.452,13.452,0,0,1,3.226,4.552,1.1,1.1,0,0,1,0,.854,14.078,14.078,0,0,1-3.226,4.552A9.771,9.771,0,0,1,10,47.556a9.768,9.768,0,0,1-6.687-2.8A14.132,14.132,0,0,1,.085,40.205a1.11,1.11,0,0,1,0-.854A13.5,13.5,0,0,1,3.313,34.8,9.774,9.774,0,0,1,10,32a9.777,9.777,0,0,1,6.688,2.8ZM10,34.778a5,5,0,1,0,5,5A5,5,0,0,0,10,34.778Z" transform="translate(0 -32)" :fill="color"/>
                        </svg>
                    </a>

                </div>

                <div>
                    <button class="button--auth">Войти</button>
                </div>

            </form> 
        </div>

    </div>

    </div>
</template>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import HeaderSmall from '@/components/Header/HeaderSmall'

    export default {
        name: 'LogIn',
        data() {
            return {
                username: '',
                password: '',
                type: "password",
                user_id: "",
                errors: [],
                all_users: [],
                color: '#fff',
                status_payment: null,
                status: [],
                user_account: {},
            }
        },
        components: {
            HeaderSmall
        },
        beforeCreate() {
          this.$store.commit('initializeStore')
        },
        mounted() {
            document.title = 'Войти'
        },
        methods: {
            errorAlert() {
                for (let i = 0; i < this.errors.length; i++) {
                    const message_error = this.errors[i]
                    toast({
                        message: message_error,
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                }
            },
            async paymentStatus(sub) {
                await axios
                    .get(`/api/v1/paymentStatus/${sub}/`)
                    .then(response => {
                        this.status = response.data

                        for (let i = 0; i < this.status.length; i++) {
                            const item = this.status[i]

                            if (item[1] == 'succeeded') {
                                this.status_payment = true
                            }
                        }

                    })
                    .catch(error => {
                        console.log(error)
                    })                
            },
            alertWarning() {
                toast({
                    message: 'Пожалуйста подождите....',
                    type: 'is-warning',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 1000,
                    position: 'bottom-right',
                })
            },
            StatusSuccess() {
                if (this.status_payment == true) {
                    this.$store.commit('setPayStatus', this.status_payment)

                    localStorage.setItem("status_pay", this.status_payment)
                }
            },
            async CheckProfile(value) {
                await axios
                    .get(`/api/v1/me/check/${value}/`)
                    .then(response => {
                        this.user_account = response.data
                    })
                    .catch(error => {
                        console.log(error)
                    })
            },
            submit() {
                this.CheckProfile(this.username)

                setTimeout(() => {
                    this.submitForm()
                }, 500)
            },
            async submitForm() {
                axios.defaults.headers.common["Authorization"] = ""

                localStorage.removeItem("token")

                const formData = {
                    username: this.username,
                    password: this.password
                }

                // await axios
                //     .get(`/api/v1/myProfiles/`)
                //     .then(response => {
                //         this.all_users = response.data
                //     })
                //     .catch(error => {
                //         console.log(error)
                //     })

                // for (let i = 0; i < this.all_users.length; i++) {
                //     const item = this.all_users[i]
                //     let check_user = item.email

                //     if (check_user == this.username) {
                //         this.user_account = item
                //     }
                // }
                await axios
                .post("/api/v1/token/login/", formData)
                .then(response => {
                    const token = response.data.auth_token

                    this.$store.commit('setToken', token)
                    
                    axios.defaults.headers.common["Authorization"] = "Token " + token

                    localStorage.setItem("token", token)

                    this.$store.commit('setUser', this.user_account.id)

                    localStorage.setItem("user_id", this.user_account.id)

                    localStorage.setItem("user", this.user_account)

                    this.$store.commit('setUserInfo', this.user_account)

                    let sub_id = this.$store.state.user.sub;

                    if (sub_id) {
                        this.paymentStatus(sub_id)
                        this.alertWarning()
                        setTimeout(() => {
                            this.StatusSuccess()
                            this.$router.push('/my-account')
                        }, 1000)
                        
                    } else {
                        this.$router.push('/')
                    }
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${error.response.data[property]}`)
                        }
                        this.errorAlert();
                    } else {
                        this.errors.push('Что-то пошло не так...')

                        this.errorAlert();
                        
                        console.log(JSON.stringify(error))
                    }
                })
            },

            openPass() {
                if (this.color == '#000') {
                    this.color = '#EB3624'
                } else {
                    this.color = '#000'
                }

                if (this.type == 'password') {
                    this.type = 'text'
                } else {
                    this.type = 'password'
                }
                
            },
        },
        computed: {
          validate_username() {
            if(this.username.length > 0) {
              return true;
            } else {
              return false;
            }
          },
          validate_password() {
            if(this.password.length > 0) {
              return true;
            } else {
              return false;
            }
          }          
        },
        created() {

        },
    }
</script>