<template>
	<nav>
      <div class="container paddingcontainernew">
        <div class="header-row">
          <div class="d-flex justify-content-between w-100"> 

          	<div class="d-flex justify-content-between width_start">
              <router-link class="rd-nav-link-konkurs_small" to="/">Главная</router-link>

              <router-link class="rd-nav-link-konkurs_small d-hiden" to="/?redirect=contact">Контакты</router-link>

              <router-link class="rd-nav-link-konkurs_small d-hiden" to="/about_project">О проекте</router-link>

              <router-link class="rd-nav-link-konkurs_small d-hiden" to="/?redirect=parthners">Партнеры</router-link>
            </div>
            <div class="d-flex justify-content-end">
              <div class="small-header" v-if="!$store.state.isAuthenticated">
                <router-link class="rd-nav-link-konkurs_small" to="/log-in">Войти</router-link>
                <router-link class="rd-nav-reg-konkurs" to="/sign-up">Регистрация</router-link>
              </div>
              <router-link v-else class="rd-nav-reg-konkurs" to="/my-profile">Мой профиль</router-link>
            </div>
          </div>          
        </div>
      </div>
	</nav>
</template>

<style scoped>

</style>

<script>
	export default {
		name: 'HeaderSmall',
    mounted() {
      this.$store.commit('initializeStore')
    },
    methods: {
         GoLinkRef(value) {
             this.$router.push({path: '/', query: { redirect: value }})
         },
    },

	}
</script>