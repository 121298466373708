<template>
  <section class="section-posli">
      <div class="section-posli__title">
          <h1 v-if="type === 'posli'"><span class="red">ПОСЛЫ</span><br><i>ПРОЕКТА</i></h1>
          <h1 v-else><span class="red">ЖЮРИ</span><br><i>КОНКУРСА</i></h1>
      </div>
      <div class="section-posli__other" v-if="type === 'posli'">
          <h2>
            Если вы решили стать послом проекта,<br> просьба связаться  с координатором проекта 
          </h2>        
      </div>
      <div class="container section-posli__block">
          <div class="section__list">
              <div class="item-posli" v-for="item, index in items" :key="index">
                  <div class="item-posli__img">
                      <img :src="item.image">
                  </div> 
                  <div class="item-posli__title">
                      <h3>{{ item.name }}</h3>
                      <p v-html="item.description"></p>                    
                  </div>
              </div> 
          </div>
      </div>
  </section>
</template>

<script>
  export default {
    name: 'PosliList',
    props: {
      items: {
        type: Array,
        default: [], 
      },
      type: {
        type: String,
        default: 'posli', 
      },
    },
    data() {
      return {
    
      }
    },    
  }
</script>