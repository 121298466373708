<template>
   <section class="konkurs-page">
      <div class="container-full">
          <Header :banner="true" />
      </div>
      <Swiper class="konkurs-page__banner" :modules="modules" slides-per-view="auto" :pagination="{ clickable: true }">
         <SwiperSlide v-for="item in banners">
          <div class="item-banner">
              <img :src="item.image">
          </div>
          <div class="container-full">
              <div class="item-banner__title">
                  <h1>
                      ВСЕР<i>О</i>ССИЙСКИЙ<br>
                      <span>
                        К<i>О</i>НКУРС РИСУНКОВ
                      </span>
                  </h1>
              </div>
          </div>
            
         </SwiperSlide>
      </Swiper>
   </section>
   <section class="konkurs-title container-full">
     <h1><span>Всероссийский конкурс рисунков</span> – это масштабное событие, где каждый юный художник может показать свой талант! </h1>
   </section>
   <section class="konkurs-about container">
      <div class="konkurs-about__block">
        <Swiper class="konkurs-image" 
            :modules="modules" 
            slides-per-view="auto" 
            :pagination="{ clickable: true }"
            :autoplay="{
              delay: 2500,
            }"
            >
            <SwiperSlide v-for="item in bannersSecond">
              <img :src="item.image">
           </SwiperSlide>
        </Swiper>
        <div class="k-title">
          <div class="k-title__h1">
            <h1>O KOНКУРСЕ</h1>
            <p class="grey">Всероссийский конкурс рисунков<br>
            "Россия и спорт" снова объединяет юных художников со всей страны! </p>
          </div>
          <div class="k-title__p">
            <p>Конкурс вдохновляет детей на активный образ жизни и позволяет им проявить свои творческие способности. В прошлом году конкурс собрал более 8000 участников и был отмечен участием Министра спорта и олимпийских чемпионов, что подчеркивает его важность.</p>
            <p>
            В этом году конкурс станет еще масштабнее! Приглашаем всех юных художников принять участие и показать свои видения спорта! Давайте вместе создадим праздник творчества и спорта!</p>            
          </div>
        </div>
      </div>
   </section>
   <posli-list type="juri" :items="posli"></posli-list>
   <section class="konkurs-form">
      <form @submit.prevent="submit">
          <h1>
            Заполните форму для участия
          </h1>
          <div class="field--auth">
              <input type="text" class="input--auth" v-model="email"
              >
              <label class="auth--label"
              :class="{'valid--auth': validate_email}"
              >E-mail</label>
          </div>
          <div class="load-file">
              <input type="file" id="fileID" ref="file" @change="readFile()" />
              <label for="fileID" class="button--auth">
                <span v-if="file === ''">Загрузить файл</span>
                <span v-else>{{ file.name }}</span>
              </label>
              <button v-if="file && email != ''" class="button--auth" @click="PutEmail()">Отправить</button>
          </div>

      </form>      
   </section>
  <Footer/>
  
</template>
<script>
import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'
import Posli from '@/components/Posli/index'

import axios from 'axios'
import { toast } from 'bulma-toast'

import { Pagination, Autoplay} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/scss/pagination';

export default {
  name: 'KonkursPage',
  data() {
    return {
      banners: [],
      posli: [],
      bannersSecond: [],
      email: "",
      file: "",
    }
  },
  mounted() {
    this.GetPosli()
    this.GetSlider()
    this.GetBanners()
    document.title = 'Конкурс'
  },
  methods: {
      goTo(redirect) {
        this.$router.push(redirect)
      },
      async GetSlider() {
          await axios
              .get(`/api/v1/get/slides-mediateka/list`)
              .then(response => {
                  this.banners = response.data
              })
              .catch(error => {
                  console.log(error)
              })
      },       
      async GetBanners() {
          await axios
              .get(`/api/v1/get/banner-all/list`)
              .then(response => {
                  this.bannersSecond = response.data
              })
              .catch(error => {
                  console.log(error)
              })
      },    
      async GetPosli() {
          await axios
              .get(`/api/v1/get/juri-all/list`)
              .then(response => {
                  this.posli = response.data
              })
              .catch(error => {
                  console.log(error)
              })
      }, 
      readFile() {
        this.file = this.$refs.file.files[0];
        console.log(this.file)
      },
      async PutEmail() {
          const dataForm = {
              file: this.file,
              email: this.email, 
          }

          const headers = {
            'Content-Type': `multipart/form-data; boundary=Boundaryrga771iuUYap8BB2` 
          }

          await axios
              .put(`/api/v1/put/send-file/${this.file.name}/`, dataForm, {headers: headers})
              .then(response => {
                  this.file = ''
                  this.email = ''
                  toast({
                      message: 'Вы успешно подали заявку на запись мероприятия!',
                      type: 'is-success',
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 6000,
                      position: 'bottom-right',
                  })
              })
              .catch(error => {
                  toast({
                      message: 'Что-то пошло не так...',
                      type: 'is-danger',
                      dismissible: true,
                      pauseOnHover: true,
                      duration: 4000,
                      position: 'bottom-right',
                  })
                  console.log(error)
              })
      }, 
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Pagination, Autoplay],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    Pagination,
    Header,
    Footer,
    'posli-list': Posli,
  },
  computed: {
    validate_email() {
      if(this.email.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
}
</script>
