<template>
    <div class="full-color--events full_vh" style="z-index: -1; ">
        <div class="bg-ortd"></div>
        <div class="container-full">
        <Header />

        <div class="container paddingcontainernew">
            <div class="welcome-profile">
                <div class="welcome-profile__title" v-if="current__category_id == 0">
                    <h1>Информация</h1>
                    <p>Кроме того, у нас есть замечательная новость для семейной аудитории, у которых есть дети. Мы предлагаем вашему вниманию участие во Всероссийском конкурсе рисунков на тему спорта. Это отличная возможность для ваших детей проявить свою креативность и любовь к спорту, а также получить призы и признание!<br>Начните свое путешествие к отличному самочувствию уже сегодня! Если у вас возникнут вопросы, не стесняйтесь обращаться к нам.</p>
                </div>
                <div class="welcome-profile__title" v-else>
                    <h1 v-if="currentCat.name">{{ currentCat.name }}</h1>
                    <p v-if="currentCat.description">{{ currentCat.description }}</p>
                </div>
            </div>
            <div class="parners--block" style="padding-bottom: 0px !important;">
                <div class="d-flex justify-content-between margin__column">
                    <div class="" v-if="current__category_id == 0">
                        <h4 class="my-account--name d-flex">
                            Видеоролики:
                        </h4>
                    </div>
                    <div v-else>
                        <a @click="back_to_category()" class="my-account--name d-flex">
                            Назад
                        </a>                        
                    </div>
                </div>  
                <div class="row" v-if="current__category_id == 0">
                    <div class="col-lg-4" v-for="list in videos_categorys">
                        <div class="box--info" @click="setCategory(list.id, list)">
                            <h3 class="h4-title--myinfo">{{ list.name }}</h3>
                            <div class="pb-2">
                                <a @click="setCategory(list.id)" class="my--info-name mb-0"><span class="link--event">Подробнее</span></a>
                            </div>

                        </div>
                    </div>
                </div>       
                <div class="row" v-else>
                    <div class="col-lg-12">
                        <div class="column__promo">
                            <span class="column__index" v-for="(list,index) in videos" :class="{acitve__index: current_id == list.id}" @click="activeVideo(list.id)">{{ list.name }}</span>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="relative_pos fullscreen-wrapper" :class="{'full__screen': full}"> 
                            <div v-for="list in videos">
                                <div v-if="current_id === list.id">
                                    <video ref="video" class="video" controls :class="{'full__video': full}" frameborder="0" autoplay :muted="muted">
                                        <source :src="list.videoLink" type="video/mp4"> 
                                    </video>
                                    <div class="video-desciprtion">
                                        <div class="video-desciprtion__title">
                                            <h1>
                                                {{ list.name }}
                                            </h1>
                                            <p v-if="list.description">
                                                {{ list.description }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
<!--                         <h4 class="more__video">Осталось просмотреть еще {{ more }} видео</h4> -->
                    </div>
                </div>
            </div>

        </div>

        <section v-if="current__category_id == 0" style="margin-bottom: 40vh;"></section>

        <Footer :fone="false" :fone_profile="true" />
        </div>
    </div>

    
</template>

<style>
    .more__video {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: -0.01em;

        color: #D63727;
    }
    .video__index {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        /* identical to box height */

        letter-spacing: -0.01em;

        color: #fff;

        text-align: center;
        line-height: 300px;
    }
.video {
    position: relative;
    width: 100%; 
    border-radius: 24px;
}
    .margin__column {
        margin-bottom: 50px;
    }


input[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 7px;
  background: #D63727;
  border-radius: 5px;
  background-image: linear-gradient(#D63727, #D63727);
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #D63727;
  cursor: ew-resize;
/*  box-shadow: 0 0 2px 0 #555;*/
  transition: background .3s ease-in-out;
}

input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}


.footer__video {
    width: 100%;
    position: absolute;
    bottom: 0px;
    padding: 20px;
}

.margin__icon {
    width: 32px;
    height: 32px;
    margin-right: 16px;
}

.mb__foter_video {
    margin-bottom: 10px;
}

.mb__foter_10 {
    margin-bottom: 3px;
}

.relative_pos {
    position: relative;
}

.footer__sub_video {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

    .time__max {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        /* identical to box height */

        letter-spacing: -0.01em;

        color: #fff;
    }

    .full__screen {
        top: 0px;
        left: 0px;
        z-index: 99999;
        border-radius: 0px !important;
        -o-object-fit: fill;
        object-fit: fill;
    }

    .my-account--back_cat {
        color: rgb(44, 44, 44);
        font-weight: 600;
        margin-bottom: 2px;
        font-size: 20px;
    }

    .full__video {
        top: 0px;
        left: 0px;
        width: 100% !important;
        z-index: 99999;
        border-radius: 0px !important;
        -o-object-fit: fill;
        object-fit: fill;
        height: 100vh !important;
    }

    .full__footer {
        position: fixed !important;
        bottom: 0px !important;
        left: 0px !important;
        width: 100% !important;
        z-index: 99999 !important;
    }

</style>
<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

import moment from 'moment';
import { directive as fullscreen } from 'vue-fullscreen'

export default {
    name: 'PromoVideos',
    directives: {
        fullscreen
    },
    components: {
        Header,
        Footer
    },
    data() {
        return {
            user_me: {},
            user_id: "",
            videos: [],     
            full: false,
            token: "",
            videos_list: [],
            videos_listing: [],
            videos_categorys: [],
            current_id: 0,
            current__category_id: 0,
            src: "",
            muted: true,
            video_time: "",
            time_current: "",
            style_video: "",
            style_video_max: "",
            volume_check: false,
            check_video: false,
            current_duration: "0:00",
            fulltime: "0:00", 
            currentCat: {},
            options: {
                target: ".fullscreen-wrapper",
                callback (isFullscreen) {
                    
                },
           },
        }
    },
    beforeCreate() {
      this.$store.commit('initializeStore')
      const token = this.$store.state.token
      this.token = token
    },
    mounted() {
        document.title = 'Промо видео'

        this.user_id = this.$store.state.user_id

        this.GetAllCategorys()
        this.getMyAccount()
    },
    methods: {
        async getMyAccount() {
            this.$store.commit('initializeStore')

            await axios
                .get(`/api/v1/me/${this.user_id}/`)
                .then(response => {
                    this.user_me = response.data
                    this.videos_listing = response.data.videos_listing
                })
                .catch(error => {
                    console.log(error)
                })
        },
        // async PutMyAccount(new_video_id) {

        //     console.log(new_video_id)

        //     let video_check = this.videos_listing.find(video => video === new_video_id)

        //     if(video_check == undefined) {
        //         this.videos_listing.push(new_video_id)
        //     }

        //     const UpdateData = {
        //         videos_listing: this.videos_listing
        //     }

        //     await axios
        //         .put(`/api/v1/me/${this.user_id}/`, UpdateData)
        //         .then(response => {
        //             this.user_me = response.data
        //             this.videos_listing = response.data.videos_listing
        //         })
        //         .catch(error => {
        //             console.log(error)
        //         })
        // },
        async GetAllCategorys() {
            await axios
                .get(`/api/v1/get/catalog-videos/list`)
                .then(response => {
                    console.log(response.data)
                    this.videos_categorys = response.data
                })
                .catch(error => {
                    console.log(error)
                })
        },
        async GetVideosCategory(cat_id) {
            await axios
                .get(`/api/v1/get/videos-category/${cat_id}/object`)
                .then(response => {
                    console.log(response.data[0])
                    this.videos = response.data
                    if(response.data[0]) {
                        this.current_id = response.data[0].id 
                   }
                   console.log(this.current_id)
                })
                .catch(error => {
                    console.log(error)
                })
        },
        activeVideo(index) {
            this.current_id = index;
            // this.PutMyAccount(this.current_id)
            // setTimeout(() => {
            //     this.addListenersCurrentTime();
            //     this.addListenersDuration();
            // }, 1000)
        },
        addListenersCurrentTime() {
            this.$refs.video.addEventListener('timeupdate',this.videoTimeUpdated(),false);
        },
        addListenersDuration() {
            this.$refs.video.addEventListener('timeupdate',this.videoLenght(),false);
        },
        videoTimeUpdated() {
            this.time_current = this.$refs.video.currentTime
            this.secondsToTimeDuration(this.time_current)
            this.styleVideo()
        },
        videoLenght() {
            this.video_time = this.$refs.video.duration
            this.secondsToTime(this.video_time)
        },
        play() {
            this.check_video = false;
            this.$refs.video.play()
        },
        pause() {
            this.check_video = true;
            this.$refs.video.pause()
        },
        volume() {
            this.volume_check = !this.volume_check
            this.muted = !this.muted;
        },
        fullscreen() {
            this.full = !this.full
        },
        styleVideo() {
            var time = Math.floor(this.time_current)
            this.style_video = time + "%"
            this.style_video_max = '100%'
        },
        secondsToTime(time) {
            var h = Math.floor(time / (60 * 60)),
                dm = time % (60 * 60),
                m = Math.floor(dm / 60),
                ds = dm % 60,
                s = Math.ceil(ds);
            if (s === 60) {
                s = 0;
                m = m + 1;
            }
            if (s < 10) {
                s = '0' + s;
            }
            if (m === 60) {
                m = 0;
                h = h + 1;
            }
            if (m < 10) {
                m = '0' + m;
            }
            if (h === 0) {
                this.fulltime = m + ':' + s;
            } else {
                this.fulltime = h + ':' + m + ':' + s;
            }
        },
        secondsToTimeDuration(time) {
            var h = Math.floor(time / (60 * 60)),
                dm = time % (60 * 60),
                m = Math.floor(dm / 60),
                ds = dm % 60,
                s = Math.ceil(ds);
            if (s === 60) {
                s = 0;
                m = m + 1;
            }
            if (s < 10) {
                s = '0' + s;
            }
            if (m === 60) {
                m = 0;
                h = h + 1;
            }
            if (m < 10) {
                m = '0' + m;
            }
            if (h === 0) {
                this.current_duration = m + ':' + s;
            } else {
                this.current_duration = h + ':' + m + ':' + s;
            }
        },
        data_event(date) {
          moment.locale('ru');
          const datasort = moment(String(date)).format('DD MMMM YYYY')
          return datasort
        },  
        back_to_category() {
            this.current__category_id = 0
            this.videos = []
            this.current_id = 0
        },
        setCategory(id, obj) {
            console.log(obj)
            this.currentCat = obj
            this.current__category_id = id
            this.GetVideosCategory(id)
        }
    },
    computed: {
        // more() {
        //     const log = (this.videos.length - this.current_id) - 1
        //     return log
        // },
    },
    watch: {

    }        
}
</script>