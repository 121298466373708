<template>
  <div class="black__block_konkurs" :class="{'coloreNer': fone, 'tranparent__footer': !fone}" v-if="fone_profile">
    <div class="d-flex justify-content-between w-100 pt__footer container paddingcontainernew">
    		<div class="w-100 padding__footer">
    			<div class="d-flex justify-content-between width__footer">
	        <router-link class="rd-nav-link-konkurs-footer" to="/">Главная</router-link>

	        <router-link class="rd-nav-link-konkurs-footer" to="/?redirect=contact">Контакты</router-link>

	        <router-link class="rd-nav-link-konkurs-footer" to="/about_project">О проекте</router-link>

	        <router-link class="rd-nav-link-konkurs-footer" to="/?redirect=parthners">Партнеры</router-link>   

	        <router-link v-if="!$store.state.isAuthenticated" class="rd-nav-link-konkurs-footer" to="/log-in">Войти</router-link>     				
    			</div>
	        <div class="width__footer__sub">
	        	<a class="sub__foter__end">Все права защищены движение2024.рф</a>
	        </div>   
    		</div> 		
<!--     		<div class="right_end">
    			<img src="@/assets/img/logo__footer.svg">
    		</div> -->
    </div>
  </div>		
  <div class="black__block_konkurs fone_profile" v-else>
    <div class="d-flex justify-content-between w-100 pt__footer container paddingcontainernew">
    		<div class="w-100 padding__footer">
    			<div class="d-flex justify-content-between width__footer">
	        <router-link class="rd-nav-link-konkurs-footer" to="/">Главная</router-link>

	        <router-link class="rd-nav-link-konkurs-footer" to="/?redirect=contact">Контакты</router-link>

	        <router-link class="rd-nav-link-konkurs-footer" to="/about_project">О проекте</router-link>

	        <router-link class="rd-nav-link-konkurs-footer" to="/?redirect=parthners">Партнеры</router-link>   

	        <router-link v-if="!$store.state.isAuthenticated" class="rd-nav-link-konkurs-footer" to="/log-in">Войти</router-link>     				
    			</div>
	        <div class="width__footer__sub">
	        	<a class="sub__foter__end">Все права защищены движение2024.рф</a>
	        </div>   
    		</div> 		
<!--     		<div class="right_end">
    			<img src="@/assets/img/logo__footer.svg">
    		</div> -->
    </div>
  </div>	
</template>

<style scoped>
	.fone_profile {
		background-color: #2C2C2C !important;
	}
</style>

<script>
	export default {
		name: 'Footer',
		props: {
			fone: {
				type: Boolean,
				default: true,
			},
			fone_profile: {
				type: Boolean,
				default: false,				
			},
		}
	}
</script>