<template>
    <div class="full--auth--back" :style="{'background-image': 'url(' + require('@/assets/img/auth.png') + ')'}">

    <HeaderSmall />

    <div class="position--auth-form">
        <div class="max--form">
            <h5 class="auth--title">Создать аккаунт</h5>
            <form>
                <div v-if="step === 1">
                    <div class="field--auth">
                        <input type="text" class="input--auth" v-model="username"
                        >
                        <label class="auth--label"
                        :class="{'valid--auth': validate_username}"
                        >E-mail</label>
                    </div>

                    <div class="field--auth">
                        <input :type="type" class="input--auth" v-model="password">
                        <label 
                            class="auth--label"
                            :class="{'valid--auth': validate_password}"
                        >Пароль</label>
                        <a @click="openPass()" class="btn-eye--pos">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.556" viewBox="0 0 20 15.556">
                              <path id="eye-solid" d="M9.709,36.459A2.731,2.731,0,0,1,10,36.445a3.333,3.333,0,1,1-3.333,3.333,2.733,2.733,0,0,1,.014-.292,2.549,2.549,0,0,0,1.1.292A2.224,2.224,0,0,0,10,37.556,2.549,2.549,0,0,0,9.709,36.459Zm6.979-1.66a13.452,13.452,0,0,1,3.226,4.552,1.1,1.1,0,0,1,0,.854,14.078,14.078,0,0,1-3.226,4.552A9.771,9.771,0,0,1,10,47.556a9.768,9.768,0,0,1-6.687-2.8A14.132,14.132,0,0,1,.085,40.205a1.11,1.11,0,0,1,0-.854A13.5,13.5,0,0,1,3.313,34.8,9.774,9.774,0,0,1,10,32a9.777,9.777,0,0,1,6.688,2.8ZM10,34.778a5,5,0,1,0,5,5A5,5,0,0,0,10,34.778Z" transform="translate(0 -32)" :fill="color"/>
                            </svg>
                        </a>
                    </div>

                    <div class="field--auth">
                        <input :type="type" class="input--auth" v-model="password2">
                        <label 
                            class="auth--label"
                            :class="{'valid--auth': validate_password_2}"
                        >Подтверждение пароля</label>
                        <a @click="openPass()" class="btn-eye--pos">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.556" viewBox="0 0 20 15.556">
                              <path id="eye-solid" d="M9.709,36.459A2.731,2.731,0,0,1,10,36.445a3.333,3.333,0,1,1-3.333,3.333,2.733,2.733,0,0,1,.014-.292,2.549,2.549,0,0,0,1.1.292A2.224,2.224,0,0,0,10,37.556,2.549,2.549,0,0,0,9.709,36.459Zm6.979-1.66a13.452,13.452,0,0,1,3.226,4.552,1.1,1.1,0,0,1,0,.854,14.078,14.078,0,0,1-3.226,4.552A9.771,9.771,0,0,1,10,47.556a9.768,9.768,0,0,1-6.687-2.8A14.132,14.132,0,0,1,.085,40.205a1.11,1.11,0,0,1,0-.854A13.5,13.5,0,0,1,3.313,34.8,9.774,9.774,0,0,1,10,32a9.777,9.777,0,0,1,6.688,2.8ZM10,34.778a5,5,0,1,0,5,5A5,5,0,0,0,10,34.778Z" transform="translate(0 -32)" :fill="color"/>
                            </svg>
                        </a>
                    </div>
                    <div class="mb-5">
                        <div>
                            <button class="button--auth" type="button" @click="NextStep()">Следующий этап</button>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <h5 class="step">1 Этап</h5>
                        <h5 class="step text-right">Остался 1 этап</h5>
                    </div>
                </div>
                <div v-if="step == 2">
                    
                    <div class="field--auth">
                        <input type="text" maxlength="40" class="input--auth" v-model="fio"
                        >
                        <label class="auth--label"
                        :class="{'valid--auth': fioValidate}"
                        >Фио</label>
                    </div>


                    <div class="field--auth">
                        <input class="input--auth birtdata" v-model="birthday" placeholder="дд.мм.гггг" type="text" v-mask="'##.##.####'" style="padding-right: 1.25rem !important;" 
                        >
                        <label class="auth--label valid--auth"
                        >Дата рождения (дд.мм.гггг)</label>
                    </div>

                    <div class="field--auth">
                        <select class="input--auth" v-model="region" style="height: 46px;">
                            <option value="Выберите регион:">Выберите регион:</option>
                            <option v-for="item in regions" :value="item.title">{{ item.title }}</option>
                        </select>
                    </div>

                    <div class="error_birt" v-if="birthday_error_show">
                        <p>Ошибка даты. Введите дату в формате день.месяц.год</p>
                    </div>

                    <div class="mb-5 d-flex justify-content-between">
                        <button class="button--back-anketa" type="button" @click="BackStep()">Назад</button>
                        <div>
                            <button class="button--next-anketa" type="button" @click="submitForm()">Готово</button>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <h5 class="step">2 Этап</h5>
                    </div>
                    
                </div>
            </form> 
        </div>

    </div>
    </div>
</template>

<style>

</style>

<script>
    import axios from 'axios'
    import { toast } from 'bulma-toast'
    import { mask } from 'vue-the-mask'
    import moment from 'moment';
    import HeaderSmall from '@/components/Header/HeaderSmall'

    export default {
        name: 'SignUp',
        directives: { mask },
        data() {
            return {
                username: '',
                password: '',
                password2: '',
                type: "password",
                errors: [],
                color: '#fff',
                step: 1,
                fio: "",
                region: "Выберите регион:",
                birthday: "",
                ready: false,
                birthday_error_show: false,

                regions: [
                  {
                    "title": "Брянская область",
                  },
                  {
                    "title": "Владимирская область",
                  },
                  {
                    "title": "Волгоградская область",
                  },
                  {
                    "title": "Рязанская область",
                  },
                  {
                    "title": "Кемеровская область",
                  },
                  {
                    "title": "Воронежская область",
                  },
                  {
                    "title": "Мурманская область",
                  },
                  {
                    "title": "Новосибирская область",
                  },
                  {
                    "title": "Тверская область",
                  },
                  {
                    "title": "Томская область",
                  },
                  {
                    "title": "Тульская область",
                  },
                  {
                    "title": "Ульяновская область",
                  },
                  {
                    "title": "Ярославская область",
                  },
                  {
                    "title": "Камчатский край",
                  },
                  {
                    "title": "Краснодарский край",
                  },
                  {
                    "title": "Приморский край",
                  },
                  {
                    "title": "Ставропольский край",
                  },
                  {
                    "title": "Республика Дагестан",
                  },
                  {
                    "title": "Луганская Народная Республика",
                  },
                  {
                    "title": "Республика Татарстан",
                  },
                  {
                    "title": "г. Москва",
                  },
                  {
                    "title": "г. Санкт-Петербург",
                  },
                  {
                    "title": "г. Иркутск",
                  },
                  {
                    "title": "Курганская область",
                  },
                  {
                    "title": "Чувашская Республика",
                  },
                  {
                    "title": "Липецкая область",
                  },
                  {
                    "title": "Нижегородская область",
                  },
                  {
                    "title": "Республика Мордовия",
                  },
                  {
                    "title": "Республика Карелия",
                  },
                  {
                    "title": "Самарская область",
                  },
                ],

            }
        },
        components: {
            HeaderSmall
        },
        mounted() {
            document.title = 'Создать аккаунт'
        },
        methods: {
            errorAlert() {
                for (let i = 0; i < this.errors.length; i++) {
                    const message_error = this.errors[i]
                    toast({
                        message: message_error,
                        type: 'is-danger',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                }
            },
            errorCheck() {
                this.errors = []

                if (this.username === '') {
                    this.errors.push('Укажите электронную почту.');
                } else if (!this.validEmail(this.username)) {
                    this.errors.push('Укажите корректный адрес электронной почты.')
                }

                if (this.password === '') {
                    this.errors.push('Пароль не заполнен')
                }

                if (this.password !== this.password2) {
                    this.errors.push('Пароль не совпадает')
                }

                if (this.fio === '' && this.step == 2) {
                    this.errors.push('ФИО не заполнено')
                }

                if (this.region === 'Выберите регион:' && this.step == 2) {
                    this.errors.push('Регион не выбран')
                }

                if (!this.ready && this.step == 2) {
                    this.errors.push('Дата заполнена не верно!')
                }
                
                if (!this.errors.length) {
                    return true
                } else {
                    this.errorAlert();
                    return false
                }
            },
            birtCheck(value) {
                var newValue = value.split('.')
                if (newValue.length == 3) {
                    if (newValue[2].length == 4) {
                        if (Number(newValue[0]) <= 31 && Number(newValue[1] <= 12) && Number(newValue[2] > 1900)) {
                            this.ready = true
                            this.birthday_error_show = false
                        }
                        else {
                            this.ready = false
                            this.birthday_error_show = true
                        }
                        
                    }
                    else {
                        this.ready = false
                    }
                }
            },
            BackStep() {
                this.step--
            },
            NextStep() {
                if (this.errorCheck()) {
                    this.step++
                }
            },
            async LogInNew() {
                const formData = {
                    username: this.username,
                    password: this.password
                }

                axios.defaults.headers.common["Authorization"] = ""
                localStorage.removeItem("token")

                await axios
                .post("/api/v1/token/login/", formData)
                .then(response => {
                    const token = response.data.auth_token
                    this.$store.commit('setToken', token)
                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    localStorage.setItem("token", token)
                    toast({
                        message: 'Регистрация прошла успешно',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                    this.$router.push('/')
                })
                .catch(error => {
                    if (error.response) {
                        for (const property in error.response.data) {
                            this.errors.push(`${property}: ${error.response.data[property]}`)
                        }
                        this.errorAlert();
                    } else {
                        this.errors.push('Что-то пошло не так...')

                        this.errorAlert();
                        
                        console.log(JSON.stringify(error))
                    }
                })
            },
            async submitForm() {
                if (this.errorCheck()) {
                    const databirthday = moment(String(this.birthday), "DD.MM.YYYY").format('YYYY-MM-DD')
                    console.log(databirthday)
                    const formData = {
                        fio: this.fio,
                        username: this.username,
                        password: this.password,
                        birthday: databirthday,
                        email: this.username,
                        region: this.region
                    }

                    await axios
                        .post("/api/v1/users/", formData)
                        .then(response => {
                            this.LogInNew()
                        })  
                        .catch(error => {
                            if (error.response) {
                                for (const property in error.response.data) {
                                    if (property === 'username'){
                                        this.errors.push('Пользователей с такой почтой уже существует!')
                                    }
                                }
                                this.errorAlert();
                            } else if (error.message) {
                                this.errors.push('Попробуйте еще раз, произошла ошибка')
                                this.errorAlert();
                            }
                        })
                }
            },
            openPass() {
                if (this.color == '#fff') {
                    this.color = '#EB3624'
                } else {
                    this.color = '#fff'
                }

                if (this.type == 'password') {
                    this.type = 'text'
                } else {
                    this.type = 'password'
                }    
            },
            validEmail(email) {
              var re = /@\w+([\.-]?\w+)/;
              return re.test(email);
            }
        },
        computed: {
          validate_username() {
            if(this.username.length > 0) {
              return true;
            } else {
              return false;
            }
          },
          validate_password() {
            if(this.password.length > 0) {
              return true;
            } else {
              return false;
            }
          },
          validate_password_2() {
            if(this.password2.length > 0) {
              return true;
            } else {
              return false;
            }
          },         
          fioValidate() {
            if(this.fio.length > 0) {
              return true;
            } else {
              return false;
            }
          },
        },
        watch: {
            birthday(value) {
                this.birthday = value
                this.birtCheck(value)
            },
        },
    }
</script>