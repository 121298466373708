<template>
  <section>
    <router-view/>
    <vue-progress-bar />
  </section>
</template>

<style>
@import '@/assets/css/Main.css';
</style>

<style lang="scss">
@import '@/assets/css/main.scss';
</style>

<script>
  import axios from 'axios'

  export default {
    data() {
      return {

      }
    },
    beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token

      if (token) {
        axios.defaults.headers.common['Authorization'] = "Token " + token
      } else {
        axios.defaults.headers.common['Authorization'] = ""
      }
    },
    mounted() {
      this.$Progress.finish();

      document.title = 'Проект "Россия в движении 2024"'
    },
    created() {
      this.$Progress.start();

      this.$router.beforeEach((to, from, next) => {

        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;

          this.$Progress.parseMeta(meta);
        }

        this.$Progress.start();

        next();
      });

      this.$router.afterEach((to, from) => {
        this.$Progress.finish();
      });
    },  
    computed: {

    }
  }
</script>

<style lang="scss">
@import '../node_modules/bulma';
</style>

