<template>

<div class="full-color--events full_vh">
<div class="bg-ortd"></div>
<div class="container-full">
<Header />

        

        <div class="container paddingcontainernew">
            <div class="welcome-profile">
                <div class="welcome-profile__title">
                    <h1>Добро пожаловать</h1>
                    <p>Мы рады приветствовать вас на нашем сайте! Спасибо за регистрацию. 
                    Теперь вы можете наслаждаться тренировками по фитнес-аэробике разных уровней сложности, включая специальные программы и разминки, которые помогут вам достичь ваших целей.</p>
                </div>
            </div>
            <div class="parners--block" style="padding-bottom: 10px !important;">
                <div class="d-flex justify-content-between mb-6">
                    <div class="">
                        <h4 class="my-account--name">{{ user_me.fio }}</h4>
<!--                         <router-link class="link-edit--a" to="/edit_profile">Редактировать профиль</router-link>     -->                    
                    </div>


                    <a class="exit" @click="logout()">Выйти из аккаунта</a>
                </div>

                <div class="row mb-6">
                    <div class="col-lg-4">
                        <div class="box--info">
                            <h4 class="h4-title--myinfo">Видео тренировки</h4>
                            <div class="pb-2">
                                <router-link to="/promo_video" class="my--info-name mb-0"><span class="link--event">Подробнее</span></router-link>
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="box--info">
                            <h4 class="h4-title--myinfo">Участие в Всероссийском конкурсе рисунков</h4>
                            <div class="pb-2">
                                <router-link to="/konkurs" class="my--info-name mb-0"><span class="link--event">Подробнее</span></router-link>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <section class="donwload-files">
                <a target="_blank" href="https://xn--2024-f4dliahtc3d.xn--p1ai/dvijenie-eto-jizn.pdf"><span></span>Скачать пособие: «движение - это жизнь»</a>
                <a target="_blank" href="https://xn--2024-f4dliahtc3d.xn--p1ai/posobie.pdf"><span></span>Скачать пособие по нутрициологии</a>
                <a target="_blank" href="https://xn--2024-f4dliahtc3d.xn--p1ai/polojenie-o-konkurse.pdf"><span></span>Cкачать положение о конкурсе</a>
            </section>
        </div>

        <div>

              <div class="container paddingcontainernew">
                <div class="block--help-form">
                  <div class="mx-w--help">
                   <h5 class="text--help-form" style="color: #fff !important;">Форма обратной связи</h5>
                   <p class="help-help-text text-center">Заполните обратную форму обратной и связи, <br> и ответ на вашу проблему поступит в кратчайшее время</p>
                   <form @submit.prevent="submitForm">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="help--form-div">
                          <label class="help-form--label"
                          :class="{'valid--help-form': validate_description, 'dop__label': validate_description}"
                          >Описание</label>
                          <textarea type="text" v-model="message" class="help--input-form" />
                        </div> 
                      </div>
                    </div>

                    <div v-if="message">
                        <button class="send--mesaage-help" style="border: 0px solid #fff !important;">Отправить</button>
                    </div>
                    <div v-else>
                        <button class="send--mesaage-help disabled_button" disabled style="border: 0px solid #fff !important;">Отправить</button>
                    </div>
                    

                    <p class="help-help-text">* Все поля обязательны к заполнению</p>
                  </form>        
                </div>

              </div>
            </div>

        </div>


    </div>

<Footer  />
</div>
    
    
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'

import Header from '@/components/Header/Header'
import Footer from '@/components/Footer/Footer'

import moment from 'moment';

export default {
    name: 'MyProfile',
    components: {
        Header,
        Footer
    },
    data() {
        return {
            orders: [],
            sub: {},
            user_me: {},
            user_id: "",
            token: "",
            current_id: "",
            region: "Выберите регион:",
            modal__check_region: false,
            message: "", 
        }
    },
    beforeCreate() {
      this.$store.commit('initializeStore')

      const token = this.$store.state.token
      this.token = token
    },
    mounted() {
        document.title = 'Мой аккаунт'
        this.getMyAccount()
    },
    methods: {
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            localStorage.removeItem("username")
            localStorage.removeItem("userid")
            localStorage.removeItem("sub_id")
            localStorage.removeItem("id_pay")
            localStorage.removeItem("status_pay")
            localStorage.removeItem("anketaCheck")

            this.$store.commit('removeUser')
            this.$store.commit('removeToken')
            this.$store.commit('removeSub')
            this.$store.commit('removePayID')
            this.$store.commit('removePayStatus')

            this.$router.push('/')
        },
        async submitForm() {
            const formData = {
              email: this.user_me.email,
              message: this.message,
            }

            await axios
            .post(`/api/v1/post/send-email/default`, formData)
            .then(response => {
              toast({
                message: 'Ваше обращение успешно отправлено',
                type: 'is-success',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })
              this.message = "";
            })                
            .catch(error => {
              console.log(error)
              toast({
                message: 'Произошла ошибка',
                type: 'is-danger',
                dismissible: true,
                pauseOnHover: true,
                duration: 2000,
                position: 'bottom-right',
              })                    
            })           
        }, 
        async getMyAccount() {
            this.$store.commit('initializeStore')

            this.$store.commit('setIsLoading', true)

            await axios
                .get(`/api/v1/users/me/`)
                .then(response => {
                    this.user_me = response.data
                })
                .catch(error => {
                    console.log(error)
                })

            this.$store.commit('setIsLoading', false)
        },       
        async CheckRegion() {
          const dataForm = {
              region: this.region,
              email: this.user_me.email, 
          }

          await axios
              .put(`/api/v1/me/${this.user_id}/`, dataForm)
              .then(response => {
                  localStorage.removeItem("user")

                  this.$store.commit('removeUserInfo')                        

                  localStorage.setItem("user", response.data)

                  this.$store.commit('setUserInfo', response.data)

                  this.modal__check_region = false;
              })                
              .catch(error => {
                  console.log(error)
              })  

        },
        data_account(date) {
          moment.locale('ru');
          const datasort = moment(String(date)).format('DD MMMM YYYY')
          return datasort
        },  
    },
    computed: {
        validate_description() {
            if(this.message) {
              return true;
            } else {
              return false;
            }
        },
    } 
}
</script>